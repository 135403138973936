import React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import TICK_BOX from "../../images/tick-box.svg"
import style from "./info-module.module.css"

const InfoModule = ({
  image,
  imageAlt,
  reverseContentPosition,
  heading,
  headingLarge,
  rowOneText,
  rowTwoText,
  rowThreeText,
  rowFourText,
  removeTextMargin,
}) => {
  return (
    <div className={style.root}>
      {image && (
        <img
          className={cn(
            style.infoModuleImg,
            reverseContentPosition && style.reverseContent
          )}
          src={image}
          alt={imageAlt}
        />
      )}

      <div
        className={cn(
          style.infoModuleText,
          reverseContentPosition && style.reverseContent,
          removeTextMargin && style.removeMargin
        )}
      >
        {heading && (
          <h4
            className={cn(
              style.infoModuleHeading,
              headingLarge && style.infoModuleLinkHeadingLarge
            )}
          >
            {heading}
          </h4>
        )}

        {rowOneText && (
          <div className={style.infoModuleTextRow}>
            <img className={style.tickBoxImage} src={TICK_BOX} alt="Tick box" />
            <p className={style.rowText}>{rowOneText}</p>
          </div>
        )}
        {rowTwoText && (
          <div className={style.infoModuleTextRow}>
            <img className={style.tickBoxImage} src={TICK_BOX} alt="Tick box" />
            <p className={style.rowText}>{rowTwoText}</p>
          </div>
        )}
        {rowThreeText && (
          <div className={style.infoModuleTextRow}>
            <img className={style.tickBoxImage} src={TICK_BOX} alt="Tick box" />
            <p className={style.rowText}>{rowThreeText}</p>
          </div>
        )}
        {rowFourText && (
          <div className={style.infoModuleTextRow}>
            <img className={style.tickBoxImage} src={TICK_BOX} alt="Tick box" />
            <p className={style.rowText}>{rowFourText}</p>
          </div>
        )}
      </div>
    </div>
  )
}

InfoModule.defaultProps = {
  image: "",
  imageAlt: "",
  reverseContentPosition: false,
  heading: "",
  rowOneText: "",
  rowTwoText: "",
  rowThreeText: "",
  rowFourText: "",
  removeTextMargin: false,
}

InfoModule.propTypes = {
  image: PropTypes.string,
  imageAlt: PropTypes.string,
  reverseContentPosition: PropTypes.bool,
  heading: PropTypes.string,
  rowOneText: PropTypes.string,
  rowTwoText: PropTypes.string,
  rowThreeText: PropTypes.string,
  rowFourText: PropTypes.string,
  removeTextMargin: PropTypes.bool,
}

export default InfoModule
