import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import style from "./info-module-link.module.css"

const InfoModuleLink = ({
  image,
  imageAlt,
  heading,
  textRowOne,
  textRowTwo,
  btnLink,
  btnLabel,
}) => {
  return (
    <div className={style.root}>
      {image && (
        <img className={style.infoModuleLinkImg} src={image} alt={imageAlt} />
      )}

      <div className={style.infoModuleLinkTextWrapper}>
        {heading && <h4 className={style.infoModuleLinkHeading}>{heading}</h4>}

        <div className={style.infoModuleLinkTextGroup}>
          {textRowOne && (
            <p className={style.infoModuleLinkText}>{textRowOne}</p>
          )}

          {textRowTwo && (
            <p className={style.infoModuleLinkText}>{textRowTwo}</p>
          )}
        </div>

        {btnLink && (
          <Link className={style.infoModuleLinkButton} to={`/${btnLink}`}>
            {btnLabel}
          </Link>
        )}
      </div>
    </div>
  )
}

InfoModuleLink.defaultProps = {
  image: "",
  imageAlt: "",
  heading: "",
  textRowOne: "",
  textRowTwo: "",
  btnLink: "",
  btnLabel: "",
}

InfoModuleLink.propTypes = {
  image: PropTypes.string,
  imageAlt: PropTypes.string,
  heading: PropTypes.string,
  textRowOne: PropTypes.string,
  textRowTwo: PropTypes.string,
  btnLink: PropTypes.string,
  btnLabel: PropTypes.string,
}

export default InfoModuleLink
