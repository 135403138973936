import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout/index"

import cn from "classnames"
import InfoModule from "../components/InfoModule/index"
import InfoModuleLink from "../components/InfoModuleLink/index"
import AUTOMATE_IMAGE from "../images/automate-desktop.svg"
import INFRASTRUCUTRE_IMAGE from "../images/infrastrucutre.svg"
import GROUNDWORK_IMAGE from "../images/freight-star.svg"
import DOCUMENTS_LIVE_IMG from "../images/documents-live-mobile.svg"
import DOCUMENTS_BOX_IMG from "../images/documents-box.svg"
import DOCUMENTS_ALPHA_IMG from "../images/documents-alpha.svg"
import HOW_IT_WORKS_IMG_1 from "../images/how-it-works-one.svg"
import HOW_IT_WORKS_IMG_2 from "../images/how-it-works-two.svg"
import HOW_IT_WORKS_IMG_3 from "../images/how-it-works-three.svg"
import HOW_IT_WORKS_IMG_4 from "../images/how-it-works-four.svg"
import HOW_IT_WORKS_IMG_1_DESKTOP from "../images/how-it-works-one-desktop.svg"
import HOW_IT_WORKS_IMG_2_DESKTOP from "../images/how-it-works-two-desktop.svg"
import HOW_IT_WORKS_IMG_3_DESKTOP from "../images/how-it-works-three-desktop.svg"
import API_LOGO from "../images/freight-api-logo.svg"
import CARGO_LOGO from "../images/cargowise-logo.svg"
import FRONT_LOGO from "../images/front-logo.svg"
import EXHANGE_LOGO from "../images/exchange-logo.svg"
import OUTLOOK_LOGO from "../images/outlook-logo.svg"
import Data from "../data/data"
import style from "./freight-forwarders.module.css"

const FreightForwarders = () => {
  return (
    <>
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{Data.freightForwarders.title}</title>
          <meta
            name="description"
            content={Data.metaData.description.freightForwarders}
          />
          <link
            href="https://fonts.googleapis.com/css?family=Raleway:700&display=swap"
            rel="stylesheet"
          />
        </Helmet>

        <div className={style.root}>
          <div className={style.freightBanner}>
            <div className="container">
              <div className={`row ${style.customRow}`}>
                <div className="col s12">
                  <div className={style.freightBannerContent}>
                    <h2 className={style.freightTitle}>
                      {Data.freightForwarders.pageTitle}
                    </h2>
                    <h1 className={style.freightHeading}>
                      {Data.freightForwarders.heading}
                    </h1>
                    <div className="row">
                      <div className="col s12">
                        <p className={style.homeSubHeading}>
                          {Data.freightForwarders.subHeading}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Automate Section */}
          <div className={style.freightAutomateSection}>
            <div className="container">
              <div className="row">
                <div className="col s12">
                  <div className={style.freightAutomateModule}>
                    <InfoModule
                      image={AUTOMATE_IMAGE}
                      imageAlt={"Automate image"}
                      heading={Data.freightForwarders.automateSectionHeading}
                      rowOneText={
                        Data.freightForwarders.automateSectionParagraphOne
                      }
                      rowTwoText={
                        Data.freightForwarders.automateSectionParagraphTwo
                      }
                      rowThreeText={
                        Data.freightForwarders.automateSectionParagraphThree
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Infrastructure Section */}
          <div className={style.freightInfrastructureSection}>
            <div className="container">
              <div className="row">
                <div className="col s12">
                  <div className={style.freightInfrastructureModule}>
                    <InfoModule
                      image={INFRASTRUCUTRE_IMAGE}
                      imageAlt={"Infrastructure image"}
                      reverseContentPosition={true}
                      heading={
                        Data.freightForwarders.infrastructureSectionHeading
                      }
                      rowOneText={
                        Data.freightForwarders.infrastructureSectionParagraphOne
                      }
                      rowTwoText={
                        Data.freightForwarders.infrastructureSectionParagraphTwo
                      }
                      rowThreeText={
                        Data.freightForwarders
                          .infrastructureSectionParagraphThree
                      }
                      rowFourText={
                        Data.freightForwarders
                          .infrastructureSectionParagraphFour
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Groundwork Section */}
          <div className={style.freightGroundworkSection}>
            <div className="container">
              <div className="row">
                <div className="col s12">
                  <div className={style.freightGroundworkModule}>
                    <InfoModule
                      image={GROUNDWORK_IMAGE}
                      imageAlt={"Groundwork image"}
                      reverseContentPosition={false}
                      heading={Data.freightForwarders.groundworkSectionHeading}
                      rowOneText={
                        Data.freightForwarders.groundworkSectionParagraphOne
                      }
                      rowTwoText={
                        Data.freightForwarders.groundworkSectionParagraphTwo
                      }
                      rowThreeText={
                        Data.freightForwarders.groundworkSectionParagraphThree
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Supportd Documents Section */}
          <div className={style.freightDocumentsSection}>
            <div className="container">
              <div className={`row ${style.customRow}`}>
                <div className="col s12">
                  <h4 className={style.freightDocumentsHeading}>
                    {Data.freightForwarders.supportedDocumentsHeading}
                  </h4>

                  <div className={style.freightDocumentsGroup}>
                    <InfoModuleLink
                      image={DOCUMENTS_LIVE_IMG}
                      heading={Data.freightForwarders.liveModuleHeading}
                      textRowOne={Data.freightForwarders.liveModuleTextRowOne}
                      textRowTwo={Data.freightForwarders.liveModuleTextRowTwo}
                      btnLink={Data.freightForwarders.liveModuleBtnLink}
                      btnLabel={Data.freightForwarders.liveModuleBtnLabel}
                    />
                    <InfoModuleLink
                      image={DOCUMENTS_BOX_IMG}
                      heading={Data.freightForwarders.betaModuleHeading}
                      textRowOne={Data.freightForwarders.betaModuleTextRowOne}
                      textRowTwo={Data.freightForwarders.betaModuleTextRowTwo}
                      btnLink={Data.freightForwarders.betaModuleBtnLink}
                      btnLabel={Data.freightForwarders.betaModuleBtnLabel}
                    />
                    <InfoModuleLink
                      image={DOCUMENTS_ALPHA_IMG}
                      heading={Data.freightForwarders.alphaModuleHeading}
                      textRowOne={Data.freightForwarders.alphaModuleTextRowOne}
                      textRowTwo={Data.freightForwarders.alphaModuleTextRowTwo}
                      btnLink={Data.freightForwarders.alphaModuleBtnLink}
                      btnLabel={Data.freightForwarders.alphaModuleBtnLabel}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* How It Works Section */}
          <div className={style.freightHowItWorksSection}>
            <div className="container">
              <div className={`row ${style.customRow}`}>
                <div className="col s12">
                  <h4 className={style.freightHowItWorksHeading}>
                    {Data.freightForwarders.howItWorksHeading}
                  </h4>

                  <div className={style.freightHowItWorksContent}>
                    <img
                      className={style.freightHowItWorksOne}
                      src={HOW_IT_WORKS_IMG_1}
                      alt="How it works"
                    />
                    <img
                      className={style.freightHowItWorksOneDesktop}
                      src={HOW_IT_WORKS_IMG_1_DESKTOP}
                      alt="How it works"
                    />
                    <img
                      className={style.freightHowItWorksTwo}
                      src={HOW_IT_WORKS_IMG_2}
                      alt="How it works"
                    />
                    <img
                      className={style.freightHowItWorksThree}
                      src={HOW_IT_WORKS_IMG_3}
                      alt="How it works"
                    />
                    <img
                      className={style.freightHowItWorksFour}
                      src={HOW_IT_WORKS_IMG_4}
                      alt="How it works"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={style.freightHowItWorksSectionTwo}>
            <div className="container">
              <div className={`row ${style.customRow}`}>
                <div className="col s12">
                  <img
                    className={style.freightHowItWorksTwoDesktop}
                    src={HOW_IT_WORKS_IMG_2_DESKTOP}
                    alt="How it works"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={style.freightHowItWorksSectionThree}>
          <div className="container">
            <div className={`row ${style.customRow}`}>
              <div className="col s12">
                <img
                  className={style.freightHowItWorksThreeDesktop}
                  src={HOW_IT_WORKS_IMG_3_DESKTOP}
                  alt="How it works"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Integration Section */}
        <div className={style.freightIntegrationSection}>
          <div className="container">
            <div className={`row ${style.customRow}`}>
              <div className="col s12">
                <h4 className={style.freightIntegrationHeading}>
                  Integrate directly with…
                </h4>
                <div className={style.freightIntegrationContent}>
                  <div className={style.apiDocModule}>
                    <img
                      className={style.apiLogo}
                      src={API_LOGO}
                      alt="Api logo"
                    />
                    <a
                      href="http://docs.shipamax.com/"
                      className={style.freightIntegrationLink}
                    >
                      View Developer Docs
                    </a>
                  </div>

                  <img
                    className={cn(
                      style.freightIntegrationLogo,
                      style.cargoLogo
                    )}
                    src={CARGO_LOGO}
                    alt="Api logo"
                  />
                  <img
                    className={cn(
                      style.freightIntegrationLogo,
                      style.frontLogo
                    )}
                    src={FRONT_LOGO}
                    alt="Front logo"
                  />
                  <img
                    className={cn(
                      style.freightIntegrationLogo,
                      style.exchangeLogo
                    )}
                    src={EXHANGE_LOGO}
                    alt="Exchange logo"
                  />
                  <img
                    className={cn(
                      style.freightIntegrationLogo,
                      style.outlookLogo
                    )}
                    src={OUTLOOK_LOGO}
                    alt="Outlook logo"
                  />
                </div>

                <div className={style.freightIntegrationContentDesktop}>
                  <img
                    className={cn(
                      style.freightIntegrationLogo,
                      style.cargoLogo
                    )}
                    src={CARGO_LOGO}
                    alt="Api logo"
                  />
                  <img
                    className={cn(
                      style.freightIntegrationLogo,
                      style.frontLogo
                    )}
                    src={FRONT_LOGO}
                    alt="Front logo"
                  />
                  <div className={style.apiDocModule}>
                    <img
                      className={style.apiLogo}
                      src={API_LOGO}
                      alt="Api logo"
                    />
                    <a
                      href="http://docs.shipamax.com/"
                      className={style.freightIntegrationLink}
                    >
                      View Developer Docs
                    </a>
                  </div>
                  <img
                    className={cn(
                      style.freightIntegrationLogo,
                      style.exchangeLogo
                    )}
                    src={EXHANGE_LOGO}
                    alt="Exchange logo"
                  />
                  <img
                    className={cn(
                      style.freightIntegrationLogo,
                      style.outlookLogo
                    )}
                    src={OUTLOOK_LOGO}
                    alt="Outlook logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

FreightForwarders.propTypes = {}

export default FreightForwarders
